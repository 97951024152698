import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default function useLogList() {
  // Use toast
  const toast = useToast()

  const refReportsListTable = ref(null)

  // Table Handlers
  const tableReportsColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Type', key: 'type', sortable: true },
    { label: 'Status', key: 'status', sortable: true },
    { label: 'Export locatie', key: 'exportLocation ', sortable: true },
    { label: 'Vanaf', key: 'periodFrom', sortable: false },
    { label: 'Tot', key: 'periodTo', sortable: false },
    { label: 'Datum aangemaakt', key: 'timestamp', sortable: true },
    { label: 'Acties', key: 'actions' },
  ]

  const perPage = ref(10)
  const totalReports = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 75, 100]
  const showOverlay = ref(true)
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refReportsListTable.value ? refReportsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReports.value,
    }
  })

  const refetchData = () => {
    refReportsListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchReports = (ctx, callback) => {
    store
      .dispatch('apps-reports/fetchReports', {
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalReports.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ophalen rapportage',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
          },
        })
      })
  }

  const resolveType = type => {
    if (type === 7) return 'Productieoverzicht'
    if (type === 1) return 'FA Productieoverzicht TOR'
    if (type === 2) return 'FA Productieoverzicht BFS'
    if (type === 3) return 'Weekoverzicht  FO'
    if (type === 4) return 'Productie doorlooptijden'
    if (type === 5) return 'Productie agents'
    if (type === 6) return 'Prolongatie overzicht'
    if (type === 8) return 'Productie agent inbehandeling'
    if (type === 99) return 'GVS export'
    return ''
  }

  const resolveStatus = status => {
    if (status === 1) return 'In wachtrij'
    if (status === 2) return 'In verwerking'
    if (status === 3) return 'In verwerken naar externe partij'
    if (status === 4) return 'Afgerond'
    if (status === 5) return 'Error'
    return 'In behandeling'
  }

  const resolveLocation = location => {
    if (location === 1) return 'SFTP'
    if (location === 2) return 'Email'
    return ''
  }

  return {
    fetchReports,
    tableReportsColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    refReportsListTable,
    refetchData,
    showOverlay,
    resolveType,
    resolveStatus,
    resolveLocation,
    totalReports,
  }
}
